import React from "react";

import AvatarGroup from '@mui/material/AvatarGroup';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import NotesIcon from '@mui/icons-material/Notes';

import Avatar from "./Avatar";
import { deleteDBNote } from '../services/firebase';

function Avatars({ userInfos, users }) {
  return (
    (userInfos.length === 1)
      ? <Avatar user={userInfos[0]} users={users} />
      : <AvatarGroup>
        {userInfos.map((userInfo) => <Avatar key={userInfo.name} user={userInfo} users={users} />)}
      </AvatarGroup>
  );
}

const ListItemContent = ({ message, users }) => {
  return (<>
    <ListItemAvatar>
      {(message.uid !== null) &&
        (Array.isArray(message.uid)
          ? <Avatars userInfos={message.uid.map((uid) => ({ uid: uid }))} users={users} />
          : <Avatar user={{ uid: message.uid }} users={users} />
        )
      }
      {!message.uid && <NotesIcon />}
    </ListItemAvatar>
    <ListItemText
      primary={message.text}
      secondary={message.secondaryText}
    />
  </>)
};

function DeleteConfirmation({ onClose, open, message, users }) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Confirm delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this message?
        </DialogContentText>
        <List>
          <ListItem>
            {message && <ListItemContent message={message} users={users} />}
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onClose('confirm')} autoFocus>Confirm</Button>
      </DialogActions >
    </Dialog >
  );
}

export default function Messages({ messages, user, users, ...props }) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [messageToDelete, setMessageToDelete] = React.useState(null);
  const dense = props.dense || false;

  const deleteMessage = (message) => {
    setMessageToDelete(message);
    setOpenDialog(true);
  };

  const handleClose = (action) => {
    setOpenDialog(false);
    if (action === 'confirm')
      deleteDBNote(messageToDelete.ref);
  }

  return (
    <>
      <List dense={dense}>
        {messages.map((message, index) =>
          <ListItem
            key={index}
            secondaryAction={
              message.ref && (message.uid === user.uid) &&
              <IconButton edge="end" aria-label="delete" onClick={() => deleteMessage(message)}>
                <DeleteIcon />
              </IconButton>
            }
          >
            {message.action
              ? <ListItemButton onClick={message.action} selected={message.selected}>
                <ListItemContent message={message} users={users} />
              </ListItemButton>
              : <ListItemContent message={message} users={users} />
            }
          </ListItem>
        )
        }
      </List >
      <DeleteConfirmation open={openDialog} onClose={handleClose} message={messageToDelete} users={users} />
    </>
  );
}